// Add ScrollMagic controller for onEnter (add script via cdn in template.php)
// var _umbrella_scrollmagic_controller = new ScrollMagic.Controller({
//   globalSceneOptions: {
//     triggerHook: 'onEnter'
//   }
// });

(function ($) {
  Drupal.behaviors.umbrella = {
    attach: function (context, settings) {
    
        var maxHeight = -1;
        
        $('.office365-icons').each(function() {
         maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
        });
        
        if (maxHeight < 200) {
            maxHeight = 230;
        }
        
        $('.office365-icons').each(function() {
         $(this).height(maxHeight);
        });
        
        //console.log(maxHeight);
             
      // Callback block
//      var slideTimer = 0;
//      slideTimer = setTimeout(function() {
//          $('.call-back-block article.node-webform').slideToggle('slow');    
//      }, 10000);
    
      $('.call-back-block h2').click(function() {
//          if (slideTimer) {
//              clearTimeout(slideTimer);
//              slideTimer = 0;
//          }
//  
//         if ($('.call-back-block .error').length){
//         }
//         else {
            $('.call-back-block article.node-webform').slideToggle('slow');  
//         }
      });
      
      $(document).ajaxSuccess(function(){

        if ($('.call-back-block .error').length){
            
        }
        else {
            $('.call-back-block').delay(7000).fadeOut( 'slow');
        }
            //$.cookie('callback_cookie', 'ok');
//        var date = new Date();
//        var minutes = 30;
//        date.setTime(date.getTime() + (minutes * 60 * 1000));
//        $.cookie('callback_cookie', 'OK', { expires: date });
//        
//        $('.call-back-block').delay(7000).fadeOut( 'slow');

//         $('.call-back-block article.node-webform').hide();  
      });
      
          
      // Views filters loading.
      if ($('.exposed-filter-products').length) {
          $('.expfilter_loading').remove();
          $('.exposed-filter-products').append('<div class="expfilter_loading"></div>');
          $('.expfilter_loading').css('display','none');
          
          $('.view-id-products').ajaxStart(function(){
             $('.expfilter_loading').css('display','block');
          });
          $('#view-id').ajaxSuccess(function(){
             $('.expfilter_loading').css('display','none');
          });

      }
      
      // Hamburger menu
      $('#menu-toggle').click(function(){
        $(this).toggleClass('open');
        $('header nav').slideToggle(400,"linear", function () {
            if ($('#menu-toggle').hasClass('open')) {
                $('#menu-toggle #hamburger').fadeOut( "fast", function() {
                    $('#menu-toggle #cross').fadeIn();
                });
            }
            else {
                $('#menu-toggle #cross').fadeOut( "fast", function() {
                    $('#menu-toggle #hamburger').fadeIn();
                });
            }
        
        });
      });
      
      // Select first item gray color.
      $('select#edit-line-item-fields-field-server-type-und').change(function() {
        $(this).css('color', '#000000');
      });
      
      
      

      
      // Set current breakpoint
      var currentBreakpoint = $.getBreakpoint();
      //console.log(currentBreakpoint);

        $( window ).resize(function() {
          var currentBreakpoint = $.getBreakpoint();
          if (currentBreakpoint == 'breakpoint0') {
            $('header nav').css('display', 'none');
          }
          if (currentBreakpoint == 'breakpoint1' || currentBreakpoint == 'breakpoint2') {
            $('header nav').css('display', 'block');
          }
        });

        
        // Office 365 mobile functionality.
        // Init.
        // var currentBreakpoint = $.getBreakpoint();
        if (currentBreakpoint == 'breakpoint0') {
            $('.office365-respo').css('display', 'block');
            $('.office365-norespo').css('display', 'none');
        }
        else {
            $('.office365-respo').css('display', 'none');
            $('.office365-norespo').css('display', 'block');
        }

        // Resize.
        $( window ).resize(function() {
          var currentBreakpoint = $.getBreakpoint();
          if (currentBreakpoint == 'breakpoint0') {
            $('.office365-respo').css('display', 'block');
            $('.office365-norespo').css('display', 'none');
          }
          else {
            $('.office365-respo').css('display', 'none');
            $('.office365-norespo').css('display', 'block');
          }
        });   
             
        $('.toggle').click(function(){
            $(this).next('.toggle-wrapper').slideToggle();
        });     
        
        
        
        
      //Once needed to avoid multiple clicks when extra content is added to the page. (even vimeo vid)
      // $('.menu h2', context).once('h2').click(function () {
      //   $(this).parents('.menu').toggleClass('open');
      // });
      // $('.menu a.active', context).once('a.active').click(function (event) {
      //   event.preventDefault();
      //   $(this).parents('.menu').toggleClass('open');
      // });

      // Click away Drupal messages
//      $('.messages .close').click(function (event) {
//        event.preventDefault();
//        $(this).parents('.messages').addClass('closing');
//      });
//      $('.messages').bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
//        if ($(this).hasClass('closing')) {
//          $(this).addClass('closed');
//        }
//      });
      $('.messages a.close').remove();


      // Scrollto
      $('a[href*=#]:not([href=#])').click(function (event) {
        var path = this.href;
        var Current = window.location.pathname;

        // if link is on current page, prevent reload.
        if (path.indexOf(Current) >= 0) {
          event.preventDefault();
        }

        // get ID/hash from url
        var id = event.target.href.substring(event.target.href.indexOf("#")+1);

        $.scrollto($('#' + id), 54);
      });

      // Scrollmagic example code
      // new ScrollMagic.Scene({triggerElement: 'body'})
      //   .on("enter", function (e) {
      //     $('header').addClass('scroll');
      //   })
      //   .on("leave", function (e) {
      //     $('header').removeClass('scroll');
      //   })
      //   .offset($(window).height() + 10)
      //   .addTo(_umbrella_scrollmagic_controller);

      // Add responsive functionality to tables added via WYSIWYG
      // $('table').each(function() {
      //   if(!$(this).hasClass('responsive')) {
      //     if($(this).find('thead').length) {
      //       var headings = [];
      //       $(this).find('th').each(function(){
      //         headings.push($(this).text());
      //       });
      //       var count = 0;
      //       $(this).find('tr').each(function(){
      //         $(this).find('td').attr('data-title', headings[count-1]);
      //         ++count;
      //       });
      //     } else {
      //       $(this).find('tr').each(function(){
      //         var heading = $(this).find('th').text();
      //         $(this).find('td').each(function(){
      //           $(this).attr('data-title', heading);
      //         });
      //       });
      //     }
      //     $(this).addClass('responsive');
      //   }
      // });

    }
  };
})(jQuery);
